import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useState, useEffect, Fragment } from "react";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { ILoggingModule } from "../../../modules/api/interfaces/loggingModule";
import SearchComponent from "../../../modules/components/SearchComponent";
import Table from "../../../modules/components/table/Table";
import { add, format } from "date-fns";
import MainLayout from "../../../modules/components/MainLayout";

export function LogsPage() {
  const { data: logs, isLoading } = useFetchData<ILoggingModule[]>("/logs");
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();
  const [searchData, setSearchData] = useState<ILoggingModule[] | undefined>(
    logs
  );

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_LOGS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  useEffect(() => {
    if (searchData !== logs) {
      setSearchData(logs ? [...logs] : []);
    }
  }, [logs]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      label: "Time",
      accessor: (row: ILoggingModule) => row.timestamp,
      render: (date: Date) => {
        return format(add(new Date(date), { hours: 1 }), "dd-MM-yyyy HH:mm");
      },
    },
    {
      label: "Method",
      accessor: (row: ILoggingModule) => row.method,
      classNameCell: "text-center sm:text-left px-4 lg:px-0",
      classNameHeader: "text-center sm:text-left px-4 lg:px-0",
    },
    {
      label: "Endpoint",
      accessor: (row: ILoggingModule) => row.url,
      render: (url: string) => <span>{url.split("/api")[1]}</span>,
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },

    {
      label: "Status",
      accessor: (row: ILoggingModule) => row.statusCode,
      render: (statusCode: number) => {
        let bgColor = "bg-gray-500";

        if (statusCode >= 200 && statusCode < 300) {
          bgColor = "bg-green-500";
        } else if (statusCode >= 300 && statusCode < 400) {
          bgColor = "bg-blue-500";
        } else if (statusCode >= 400 && statusCode < 500) {
          bgColor = "bg-orange-500";
        } else if (statusCode >= 500) {
          bgColor = "bg-red-500";
        }

        return (
          <span className={`px-2 py-1 rounded-full text-white ${bgColor}`}>
            {statusCode}
          </span>
        );
      },
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
    {
      label: "User",
      accessor: (row: ILoggingModule) => row.user,
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
  ];

  return (
    <MainLayout
      title="Logs"
      searchHeader={
        <Fragment>
          <SearchComponent
            placeholder="Search by request ID..."
            data={logs || []}
            inputMode="search"
            filterAttribute="requestId"
            onSearch={(data) => {
              setSearchData(data);
            }}
          />
        </Fragment>
      }
      body={
        <Table
          data={searchData || []}
          columns={columns}
          tableActions={{
            deleteFn: (item) => console.log("Deleting", item),
            deleteMessageFn: (item) =>
              `Are you sure you want to delete ${item.requestId}?`,
          }}
          idAccessor={(row) => row.requestId}
          isLoading={isLoading}
          emptyMessage="No logs found"
        />
      }
    />
  );
}
