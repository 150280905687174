import { FC } from "react";
import SmallLoadingSpinner from "./SmallLoadingSpinner";

interface IButtonprops {
  title?: string;
  variant: "primary" | "secondary" | "icon";
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  icon?: React.ComponentType<React.ComponentProps<"svg">>;
  onClick?: () => void;
}

const Button: FC<IButtonprops> = ({
  title,
  variant,
  disabled,
  isLoading,
  icon: Icon,
  className,
  onClick,
}) => {
  let buttonVariants = {
    primary:
      "disabled:opacity-70 rounded-lg bg-customBlue text-gray-50 text-sm font-medium hover:bg-customDarkBlue active:bg-customDarkBlue active:shadow-md",
    secondary:
      "disabled:opacity-70 rounded-lg bg-gray-100 text-gray-600 text-sm font-medium hover:bg-gray-200 active:bg-gray-200 active:shadow-md",
    icon: "disabled:opacity-70 inline-flex items-center justify-center rounded-md bg-white text-gray-600 p-2.5 px-3 hover:bg-gray-100 active:bg-gray-200",
  };
  return (
    <button
      onClick={onClick}
      className={`${buttonVariants[variant]} ${className} relative`}
      disabled={disabled}
      style={{
        minWidth: variant === "icon" ? "auto" : "130px",
        minHeight: "40px",
      }}
    >
      {isLoading ? (
        <span className="absolute inset-0 flex items-center justify-center">
          <SmallLoadingSpinner />
        </span>
      ) : null}
      {!isLoading && Icon && <Icon className="h-5 w-5" />}
      {variant !== "icon" && (
        <span className={isLoading ? "hidden" : ""}>{title}</span>
      )}
    </button>
  );
};

export default Button;
