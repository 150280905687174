import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToken } from "./misc/useToken";

interface IUseCreateItemProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useCreateItem = <T,>(
  endpoint: string,
  props?: IUseCreateItemProps
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const createData = async (data: T) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.post(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["post", endpoint],
    mutationFn: (data: T) => createData(data),
    onError: (error: any) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error.response?.data || "An error occurred");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [endpoint] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate(`/dashboard${endpoint}`);
    },
  });
};
