export function LoadingSpinner({ text }: { text?: string }) {
  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white">
      <div className="flex items-center">
        <div
          className="border-gray-200 h-10 w-10 animate-spin rounded-full border-4 border-t-customBlue"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <span className="mt-2 text-gray-500 text-sm">{text}</span>
    </div>
  );
}

export default LoadingSpinner;
