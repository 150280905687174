import Chart from "react-google-charts";
import StatCard from "../../modules/components/StatCard";
import { Fragment, useEffect, useState } from "react";
import { BeakerIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
import Button from "../../modules/components/Button";
import useFetchData from "../../modules/api/hooks/useFetchData";
import { IDashboardStatistics } from "../../modules/api/interfaces/dashboard_statistics";

export const optionsReportsChart = {
  vAxis: { minValue: 0 },
  legend: { position: "none" },
  chartArea: { width: "80%", height: "80%" },
  isStacked: false,
  colors: ["#1F9EDA", "#197EAE"],
  areaOpacity: 0.4,
};

export const optionsReportsStatusChart = {
  vAxis: { minValue: 0 },
  legend: { position: "none" },
  chartArea: { width: "80%", height: "80%" },
  isStacked: false,
  colors: ["#1F9EDA", "#197EAE"],
  areaOpacity: 0.4,
};

export const optionsBikeTypesChart = {
  pieHole: 0.4,
  is3D: false,
  colors: ["#1F9EDA", "#197EAE", "#00BFFF", "#0F4C75"],
  legend: {
    position: "bottom",
    textStyle: { fontSize: 10 },
  },
  chartArea: {
    width: "80%",
    height: "80%",
  },
};

type ChartData = (string | number)[][];

export function MainPage() {
  const navigate = useNavigate();
  const { data: dashboardStats, isLoading } =
    useFetchData<IDashboardStatistics>("/statistics");
  const [dataChartOne, setDataChartOne] = useState<ChartData>();
  const [dataChartTwo, setDataChartTwo] = useState<ChartData>();
  const [dataChartThree, setDataChartThree] = useState<ChartData>();

  useEffect(() => {
    setDataChartOne(dashboardStats?.pastYearStatsOne || []);
    setDataChartTwo(dashboardStats?.pastYearStatsTwo || []);
    setDataChartThree(dashboardStats?.bikeTypes || []);
  }, [dashboardStats]);

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-4xl tracking-wide font-medium h-10">
          SwapClaim Dashboard
        </p>
      </div>
      <div className="py-8 mx-auto text-center mt-8 sm:mt-0">
        <dl className="grid gap-8 mx-auto text-gray-900 sm:grid-cols-4">
          {isLoading
            ? Array.from({ length: 4 }).map((_, index) => (
                <div
                  key={index}
                  className="h-36 bg-gray-200 rounded animate-pulse"
                ></div>
              ))
            : dashboardStats?.statistics.map((stat, index) => (
                <StatCard key={index} statistics={stat} />
              ))}
        </dl>
      </div>

      <div className="mx-auto text-center grid gap-8 grid-cols-1 sm:grid-cols-2 text-gray-900">
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className={`h-80 bg-gray-200 rounded animate-pulse ${
                index === 2 ? "sm:col-span-2" : ""
              }`}
            ></div>
          ))
        ) : (
          <Fragment>
            <div className="block p-2 bg-white border border-gray-200 rounded-lg shadow">
              <h4 className="text-lg">Reports</h4>
              <Chart
                chartType="AreaChart"
                width="100%"
                height="274px"
                data={dataChartOne}
                options={optionsReportsChart}
                className="mx-auto"
              />
            </div>

            <div className="block p-2 bg-white border border-gray-200 rounded-lg shadow">
              <h4 className="text-lg">Active & Archived reports</h4>
              <Chart
                chartType="AreaChart"
                width="100%"
                height="274px"
                data={dataChartTwo}
                options={optionsReportsStatusChart}
                className="mx-auto"
              />
            </div>

            <div className="block p-2 bg-white border border-gray-200 rounded-lg shadow sm:col-span-2">
              <h4 className="text-lg">Bike Types</h4>
              <Chart
                chartType="PieChart"
                width="100%"
                height="274px"
                data={dataChartThree}
                options={optionsBikeTypesChart}
                className="mx-auto"
              />
            </div>
          </Fragment>
        )}
      </div>

      <hr className="h-px my-8 bg-gray-200 border-2" />

      {/*<h2 className="block text-lg mt-8 mb-2 text-gray-900">
        Recent reports created
      </h2>
      <p className="text-slate-600">Comming soon...</p>
      <hr className="h-px my-8 bg-gray-200 border-2" />

      <h2 className="block text-lg mt-8 mb-2 text-gray-900">
        Recent bikes created
      </h2>
      <p className="text-slate-600">Comming soon...</p>
      <hr className="h-px my-8 bg-gray-200 border-2" />*/}

      <h1 className="text-2xl font-medium mb-1 text-black">FAQ</h1>
      <h2 className="block mt-6 mb-2 text-slate-600">How to create a Bike?</h2>
      <ol className="list-decimal ml-5 mt-2 text-slate-600">
        <li>
          Navigate to the <strong>Bikes</strong> section from the dashboard.
        </li>
        <li>
          Click the <strong>Create bike</strong> button at the top.
        </li>
        <li>
          Fill in the bike details:
          <ul className="list-disc ml-5 mt-1">
            <li>
              <strong>Frame number</strong>: Enter the unique frame number for
              the bike.
            </li>
            <li>
              <strong>Type</strong>: Select the appropriate bike type from the
              dropdown.
            </li>
            <li>
              <strong>Color</strong>: Select the appropriate color of the bike
              from the dropdown.
            </li>
            <li>
              <strong>Tracker ID</strong>: Enter the unique tracker id of the
              bike if it has one. (Only for E-bikes.)
            </li>
          </ul>
        </li>
        <li>
          Click <strong>Create bike</strong> button at the bottom to create the
          bike.
        </li>
      </ol>
      <h2 className="block mt-6 mb-2 text-slate-600">
        How to create a Report?
      </h2>
      <ol className="list-decimal ml-5 mt-2 text-slate-600">
        <li>
          Navigate to the <strong>Reports</strong> section from the dashboard.
        </li>
        <li>
          Click the <strong>Create report</strong> button at the top-right
          corner.
        </li>
        <li>
          Fill in the report details:
          <ul className="list-disc ml-5 mt-1">
            <li>
              <strong>Journal number</strong>: Enter the police journal number.
            </li>
            <li>
              <strong>Frame number</strong>: Enter the frame number of the bike
              linked to the report.
            </li>
            <li>
              <strong>Date</strong>: Select the date, that the bike was reported
              stolen.
            </li>
            <li>
              <strong>Description</strong>: Provide a brief description of the
              report. There are predefined options already.
            </li>
            <li>
              <strong>Active</strong>: Select if the report is currently active
              (Yes/No).
            </li>
          </ul>
        </li>
        <li>
          Click <strong>Create report</strong> button at the botom to create the
          report.
        </li>
      </ol>
      <hr className="h-px my-8 bg-gray-200 border-2" />
      <div className="flex justify-between">
        <div className="text-gray-400">
          Last system update: <p className="font-bold text-sm">01/12/2024</p>
        </div>
        <Button
          variant="icon"
          icon={BeakerIcon}
          className="text-gray-400"
          onClick={() => {
            navigate("/dashboard/submissions/new");
            window.scrollTo(0, 0); // scroll to top of page/form
          }}
        />
      </div>
    </div>
  );
}
