import { Link, useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Fragment, useEffect, useState } from "react";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import {
  ISubmission,
  SubmissionType,
} from "../../../modules/api/interfaces/submissions";
import Button from "../../../modules/components/Button";
import SearchComponent from "../../../modules/components/SearchComponent";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";

export function SubmissionsPage() {
  const { data: submissions, isLoading } =
    useFetchData<ISubmission[]>("/submissions");
  const [searchData, setSearchData] = useState<ISubmission[] | undefined>(
    submissions
  );
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_SUBMISSIONS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  useEffect(() => {
    if (searchData !== submissions) {
      setSearchData(submissions ? [...submissions] : []);
    }
  }, [submissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      label: "ID",
      accessor: (row: ISubmission) => row.submissionId.toString(),
    },
    {
      label: "Type",
      accessor: (row: ISubmission) => SubmissionType[row.type],
      render: (type: SubmissionType) => (
        <span
          className={`px-2 w-16 text-center rounded inline-block ${
            Number(SubmissionType[type]) === 0
              ? "bg-cyan-200 text-cyan-800"
              : "bg-indigo-200 text-indigo-800"
          }`}
        >
          {type}
        </span>
      ),
    },
    {
      label: "Title",
      accessor: (row: ISubmission) => row.title,
      render: (title: string) => {
        return title.length > 15 ? title.slice(0, 15) + "..." : title;
      },
    },
    {
      label: "Summary",
      accessor: (row: ISubmission) => row.description,
      render: (description: string) => {
        return description.slice(0, 10) + "...";
      },
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Open?",
      accessor: (row: ISubmission) => row.open === true,
      render: (suspended: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            suspended ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {suspended ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <MainLayout
      title="Submissions"
      buttons={
        <Fragment>
          <Link to="./new">
            <Button
              title="Create new"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </Fragment>
      }
      searchHeader={
        <Fragment>
          <SearchComponent
            placeholder="Search by ID..."
            data={submissions || []}
            inputMode="text"
            filterAttribute="submissionId"
            onSearch={(data) => {
              setSearchData(data);
            }}
          />
        </Fragment>
      }
      body={
        <Fragment>
          <Table
            data={
              (searchData || [])
                .sort(
                  (a: ISubmission, b: ISubmission) =>
                    Number(b.open) - Number(a.open)
                )
                .filter((item: ISubmission) => item) || []
            }
            columns={columns}
            tableActions={{
              deleteFn: (item) => console.log("Deleting", item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete ${item.submissionId}?`,
            }}
            idAccessor={(row) => row.submissionId}
            isLoading={isLoading}
            emptyMessage="No submissions found"
          />
        </Fragment>
      }
    />
  );
}
