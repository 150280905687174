import { Outlet } from "react-router-dom";
import Navbar2 from "./NavBar2";

export function Layout() {
  return (
    <>
      <Navbar2 />
      <main className="p-4 md:ml-[67px] bg-white min-h-screen">
        <Outlet />
      </main>
    </>
  );
}
