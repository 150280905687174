import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

export function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <p className="mb-6 mt-4 text-slate-500 text-sm">
      <Link to="/dashboard/statistics" className="underline">
        <HomeIcon className="w-4 h-4 mb-0.5 mr-1 inline-flex" />
        <span>Dashboard</span>
      </Link>
      {pathnames.slice(1).map((value, index) => {
        const to = `/${pathnames.slice(0, index + 2).join("/")}`;

        return (
          <Fragment key={to}>
            <ChevronRightIcon className="w-4 h-4 mx-1 inline-flex" />
            {index === pathnames.length - 2 ? (
              <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>
            ) : (
              <Link to={to} className="underline">
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </Link>
            )}
          </Fragment>
        );
      })}
    </p>
  );
}
