import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

export interface ProtectedRouteProps {
  children: ReactElement;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) return <LoadingSpinner text="Loading..." />;
  if (!isAuthenticated) return <Navigate to="/sign-in" />;
  return children;
}
