import { Link } from "react-router-dom";
import Button from "../modules/components/Button";

export function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold">404</h1>
      <p>The requested page was not found.</p>
      <Link to="/sign-in">
        <Button title="Back to login page" variant="primary" className="mt-2" />
      </Link>
    </div>
  );
}

export default NotFound;
