import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useCreateItem } from "../../../modules/api/hooks/useCreateItem";
import ReportForm, {
  ReportFormValues,
} from "../../../modules/forms/ReportForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";

export function NewReportPage() {
  const createReportMutation = useCreateItem<ReportFormValues>("/reports", {
    onError: (error) => {
      setError(error);
    },
  });
  const [error, setError] = useState("");
  const { getUser } = useKindeAuth();
  const user = getUser?.();
  const givenName = `${user?.given_name} ${user?.family_name}`;

  const initialReportData: ReportFormValues = {
    journalNumber: "",
    bikeReference: "",
    date: "",
    active: true,
    description: "",
    updatedBy: givenName,
  };

  const handleSubmit = async (data: ReportFormValues) => {
    toast.promise(createReportMutation.mutateAsync(data), {
      loading: "Creating report...",
      success: "Report created successfully!",
      error: "Error creating report.",
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <Breadcrumbs />
        <div className="flex items-center justify-between">
          <p className="text-2xl tracking-wide font-medium h-10">
            Create new report
          </p>
        </div>
        <ReportForm
          reportData={initialReportData}
          buttonPlaceholder="Create report"
          onSubmit={(data) => {
            handleSubmit(data);
          }}
        />

        {error && (
          <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
