import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { EyeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useUpdateReport } from "../../../modules/api/hooks/reports/useUpdateReport";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { IReport } from "../../../modules/api/interfaces/report";
import Button from "../../../modules/components/Button";
import ReportForm, {
  ReportFormValues,
} from "../../../modules/forms/ReportForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";

export interface IUpdateReportForm {
  journalNumber: string;
  date: string;
  description: string;
  active: string;
  bikeReference: string;
  newJournalNumber: string;
}

export function EditReportPage() {
  const { mutate: updateReport } = useUpdateReport({
    onSuccess: () => {
      toast.dismiss();
      toast.success("Report updated successfully!");
      navigate("/dashboard/reports/" + newJournalNumber);
    },
    onError: (error: { response: { data: string } }) => {
      setError(error.response.data);
      toast.dismiss();
      toast.error(error.response.data.trim().slice(0, 80));
    },
  });
  const { id } = useParams();
  const { data: reports } = useFetchData<IReport[]>("/reports");
  const navigate = useNavigate();
  const report = reports?.find((report) => report.journalNumber === id);
  const [error, setError] = useState("");
  const [newJournalNumber, setNewJournalNumber] = useState<string | undefined>(
    ""
  );

  if (!report) {
    <Navigate to="/dashboard/reports" />;
  }

  const handleSubmit = (data: ReportFormValues) => {
    toast.loading("Updating report...");
    setNewJournalNumber(data.journalNumber);
    updateReport(data);
  };

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Edit report</p>
        <Button
          variant="icon"
          icon={EyeIcon}
          onClick={() => navigate("/dashboard/reports/" + id)}
        />
      </div>
      <ReportForm
        reportData={report}
        buttonPlaceholder="Update report"
        onSubmit={(data) => {
          handleSubmit(data);
        }}
      />

      {error && (
        <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
      )}
    </div>
  );
}
