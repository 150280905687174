import { useState } from "react";
import toast from "react-hot-toast";
import { useCreateItem } from "../../../modules/api/hooks/useCreateItem";
import { BikeType } from "../../../modules/api/interfaces/bike";
import BikeForm from "../../../modules/forms/BikeForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";

export interface IBikeForm {
  frameNumber: string;
  bikeType: BikeType;
  color: string;
  trackerId?: string;
}

export function NewBikePage() {
  const createBikeMutation = useCreateItem<IBikeForm>("/bikes", {
    onError: (error) => {
      setError(error);
    },
  });

  const [error, setError] = useState("");

  const handleSubmit = async (data: IBikeForm) => {
    toast.promise(createBikeMutation.mutateAsync(data), {
      loading: "Creating bike...",
      success: "Bike created successfully!",
      error: "Error creating bike.",
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <Breadcrumbs />
        <div className="flex items-center justify-between">
          <p className="text-2xl tracking-wide font-medium h-10">
            Create new bike
          </p>
        </div>
        <BikeForm
          buttonPlaceholder="Create bike"
          onSubmit={(data) => handleSubmit(data)}
        />
        {error && (
          <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
