import React from "react";
import { Breadcrumbs } from "../layout/Breadcrumbs";

interface MainLayoutProps {
  title: string;
  buttons?: React.ReactNode;
  searchHeader?: React.ReactNode;
  body: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  buttons,
  searchHeader,
  body,
}) => {
  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-2xl tracking-wide font-medium h-10">{title}</p>
        <div className="mt-4 sm:mt-0 w-full sm:w-auto sm:flex-shrink-0">
          {buttons}
        </div>
      </div>
      <div className="flex flex-row mt-4 gap-4">{searchHeader}</div>
      {body}
    </div>
  );
};

export default MainLayout;
