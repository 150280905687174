import React from "react";
import { useController, Control } from "react-hook-form";

interface FormInputProps {
  name: string;
  type: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  type = "text",
  control,
  label,
  disabled = false,
  placeholder,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <input
        {...field}
        id={name}
        type={type}
        readOnly={type !== "date" && disabled}
        disabled={type === "date" && disabled}
        placeholder={placeholder}
        autoComplete="off"
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5 ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
};

export default FormInput;
