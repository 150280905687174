import { Link } from "react-router-dom";
import { add, format } from "date-fns";
import toast from "react-hot-toast";
import { useDeleteItem } from "../../../modules/api/hooks/useDeleteItem";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { IReport } from "../../../modules/api/interfaces/report";
import Button from "../../../modules/components/Button";
import SearchComponent from "../../../modules/components/SearchComponent";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";
import { Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";

export function ReportsPage() {
  const { data: reports, isLoading } = useFetchData<IReport[]>("/reports");
  const [searchData, setSearchData] = useState<IReport[] | undefined>(reports);
  const { mutate: deleteReport } = useDeleteItem("/reports", {
    onSuccess: () => {
      toast.success("Report deleted successfully!");
    },
    onError: () => {
      toast.error("Could not delete report. Please try again.");
    },
  });

  useEffect(() => {
    if (searchData !== reports) {
      setSearchData(reports ? [...reports] : []);
    }
  }, [reports]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (report: IReport) => {
    deleteReport({ id: report.journalNumber });
  };

  const columns = [
    {
      label: "Journal number",
      accessor: (row: IReport) => row.journalNumber,
    },
    {
      label: "Date",
      accessor: (row: IReport) => row.date,
      render: (date: Date) => {
        return format(add(new Date(date), { hours: 1 }), "dd-MM-yyyy");
      },
    },
    {
      label: "Description",
      accessor: (row: IReport) => row.description,
      render: (description: string) => {
        return description.slice(0, 80) + "...";
      },
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Frame number",
      accessor: (row: IReport) => "SF" + row.bikeReference,
    },
    {
      label: "Active?",
      accessor: (row: IReport) => row.active === true,
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
  ];

  return (
    <MainLayout
      title="Reports"
      buttons={
        <Fragment>
          <Link to="./new">
            <Button
              title="Create report"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </Fragment>
      }
      searchHeader={
        <Fragment>
          <SearchComponent
            placeholder="Search by journal number..."
            data={reports || []}
            inputMode="search"
            filterAttribute="journalNumber"
            onSearch={(data) => {
              setSearchData(data);
            }}
          />
        </Fragment>
      }
      body={
        <Fragment>
          <Table
            data={
              (searchData || [])
                .sort((a: IReport, b: IReport) => {
                  if (b.active === a.active) {
                    return (
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                    );
                  }
                  return Number(b.active) - Number(a.active);
                })
                .filter((item: IReport) => item) || []
            }
            columns={columns}
            isLoading={isLoading}
            tableActions={{
              deleteFn: (item) => handleDelete(item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete ${item.journalNumber}?`,
            }}
            idAccessor={(row) => row.journalNumber}
            emptyMessage="No reports found"
            deletePermission="DELETE_REPORT"
          />
        </Fragment>
      }
    />
  );
}
