import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../components/Button";
import { IReport } from "../api/interfaces/report";
import FormSelect from "../components/form/FormSelect";
import FormInput from "../components/form/FormInput";
import FormTextArea from "../components/form/FormTextArea";
import BikeFrameNumberInput from "../components/BikeFrameNumberInput";
import { format } from "date-fns";

export type ReportFormValues = Omit<IReport, "updatedAt"> & {
  newJournalNumber?: string;
};

// Validation schema using Yup
const reportSchema = yup.object({
  journalNumber: yup
    .string()
    .matches(/^[\d-]+$/, "Journal number must contain only numbers and dashes")
    .min(3, "Journal number must be at least 3 characters long")
    .required("Journal number is required"),
  bikeReference: yup
    .string()
    .matches(/^\d+$/, "Frame number must contain only numbers")
    .min(5, "Frame number must be at least 5 characters long")
    .required("Frame number is required"),
  date: yup
    .string()
    .required("Date is required")
    .typeError("Invalid date format"),
  active: yup
    .boolean()
    .required("Active status is required")
    .typeError("Active status must be yes or no"),
  description: yup
    .string()
    .min(10, "Description must be at least 10 characters long")
    .required("Description is required"),
  updatedBy: yup.string().required("Updated by is required"),
});

interface ReportFormProps {
  reportData?: ReportFormValues;
  readOnly?: boolean;
  buttonPlaceholder?: string;
  onSubmit?: (data: ReportFormValues) => void;
}

const ReportForm: React.FC<ReportFormProps> = ({
  reportData,
  readOnly = false,
  buttonPlaceholder = "Submit",
  onSubmit = () => {},
}) => {
  const descriptionPlaceholder = `How did it happen?:\n\nAddress:\n\nAdditional information:\n\nCreated by:\n`;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ReportFormValues>({
    defaultValues: {
      journalNumber: reportData?.journalNumber || "",
      date: reportData?.date
        ? format(new Date(reportData.date), "yyyy-MM-dd")
        : "",
      description: reportData?.description || descriptionPlaceholder,
      active: reportData?.active ?? true,
      bikeReference: reportData?.bikeReference || "",
      newJournalNumber: reportData?.journalNumber || "",
      updatedBy: reportData?.updatedBy || "",
    },
    resolver: yupResolver(reportSchema),
  });

  return (
    <form>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
        <FormInput
          name="journalNumber"
          type="text"
          control={control}
          label="Journal number"
          disabled={readOnly}
          placeholder="Journal number"
        />

        <BikeFrameNumberInput
          name="bikeReference"
          control={control}
          disabled={readOnly}
          isLink
        />

        <FormInput
          name="date"
          type="date"
          control={control}
          label="Date"
          disabled={readOnly}
        />

        <FormSelect
          name="active"
          control={control}
          label="Active?"
          disabled={readOnly}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <div className="col-span-1 sm:col-span-2">
          <FormTextArea
            name="description"
            control={control}
            label="Description"
            placeholder={"Write the report description here."}
            disabled={readOnly}
          />
        </div>
      </div>

      {!readOnly && (
        <Button
          onClick={handleSubmit(onSubmit)}
          title={buttonPlaceholder}
          variant="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          className="mt-4 w-full"
        />
      )}
    </form>
  );
};

export default ReportForm;
