import { Fragment, useState, useMemo } from "react";
import Button from "./Button";
import { FC } from "react";

interface ISearchComponentProps {
  placeholder: string;
  data: any[];
  filterAttribute: string;
  inputMode:
    | "search"
    | "email"
    | "tel"
    | "text"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
  onSearch: (filteredData: any[]) => void;
}

// Possibly use Fuse.js later, when dataset becomes larger
const SearchComponent: FC<ISearchComponentProps> = ({
  placeholder,
  onSearch,
  filterAttribute,
  inputMode,
  data,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const memoizedFilteredData = useMemo(() => {
    return data.filter((item) => {
      const attributeValue = item[filterAttribute];
      return (
        attributeValue &&
        String(attributeValue).toLowerCase().includes(searchTerm)
      );
    });
  }, [data, filterAttribute, searchTerm]);

  const handleSearch = () => {
    onSearch(memoizedFilteredData);
  };

  return (
    <Fragment>
      <input
        id="search"
        type="search"
        inputMode={inputMode}
        placeholder={placeholder}
        onChange={handleInputChange}
        className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-customBlue focus:border-white block w-full p-2.5"
      />
      <div className="w-full sm:w-auto">
        <Button
          title="Search"
          variant="secondary"
          className="w-full sm:w-auto"
          onClick={handleSearch}
        />
      </div>
    </Fragment>
  );
};

export default SearchComponent;
