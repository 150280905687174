import { useState, useEffect, useRef, useMemo } from "react";
import { useController, Control } from "react-hook-form";
import { IBike } from "../api/interfaces/bike";
import useFetchData from "../api/hooks/useFetchData";
import { useNavigate } from "react-router-dom";

interface BikeFrameNumberInputProps {
  control: Control<any>;
  name: string;
  label?: string;
  disabled?: boolean;
  isLink?: boolean;
}

export default function BikeFrameNumberInput({
  control,
  name,
  label = "Frame number",
  disabled = false,
  isLink = false,
}: BikeFrameNumberInputProps) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control });
  const { data: bikes } = useFetchData<IBike[]>("/bikes");
  const [isListOpen, setIsListOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const validateInput = (frameNumber: string) => {
    const isValidFrameNumber =
      frameNumber.length > 0 &&
      bikes?.some(
        (bike: IBike) =>
          bike.frameNumber.toLowerCase() === frameNumber.toLowerCase()
      );
    setIsValid(!!isValidFrameNumber);
  };

  useEffect(() => {
    validateInput(value || "");
  }, [value, bikes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const frameNumber = e.target.value;
    onChange(frameNumber); // Set the value in react-hook-form
    setIsListOpen(true);
    validateInput(frameNumber);
  };

  const handleItemClick = (frameNumber: string) => {
    onChange(frameNumber); // Set the selected frame number
    setIsListOpen(false);
    validateInput(frameNumber);
  };

  const filteredBikes = useMemo(() => {
    if (!value) return [];
    return bikes?.filter((bike: IBike) =>
      bike.frameNumber.toLowerCase().includes(value.toLowerCase())
    );
  }, [value, bikes]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsListOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      <input
        type="text"
        name={name}
        id={name}
        readOnly={disabled}
        className={`disabled:opacity-100 hover:read-only:text-customDarkBlue hover:read-only:underline hover:read-only:cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5 ${
          disabled ? "" : isValid ? "border-green-500" : "border-red-500"
        } ${error ? "border-red-500" : ""}`}
        placeholder="Type frame number of the bike"
        value={value || ""}
        onChange={handleInputChange}
        onClick={
          isLink && disabled
            ? () => navigate(`/dashboard/bikes/${value}`)
            : undefined
        }
      />
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}

      {isListOpen && (filteredBikes ?? []).length > 0 && (
        <ul className="absolute bg-white border border-gray-300 mt-1 rounded-lg shadow-md max-h-60 overflow-y-auto w-full z-10">
          {filteredBikes?.slice(0, 50).map((bike, index) => (
            <li
              key={index}
              className="p-2 hover:bg-customBlue hover:text-white cursor-pointer"
              onClick={() => handleItemClick(bike.frameNumber)}
            >
              {bike.frameNumber}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
