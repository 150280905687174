import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

interface ICollapsibleBlockProps {
  children: React.ReactNode;
  title: string;
}

const CollapsibleBlock: React.FC<ICollapsibleBlockProps> = ({
  children,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className="w-full text-left flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block p-2.5"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold text-gray-800">{title}</span>
        <ChevronDownIcon
          className={`w-6 h-6 transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      <div
        className={`mt-2 overflow-hidden transition-all duration-0 ${
          isOpen ? "max-h-full" : "max-h-0"
        }`}
      >
        <div className="p-4 bg-white">{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleBlock;
