import { FC, Fragment } from "react";
import { IStatistic } from "../api/interfaces/statistic";

interface IStatCardProps {
  statistics: IStatistic;
}

const StatCard: FC<IStatCardProps> = ({ statistics }) => {
  return (
    <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
      <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
        {statistics?.value}
      </dt>
      <dd className="font-light text-gray-500">{statistics?.title}</dd>
      <dd className="inline-flex items-center">
        {(statistics?.relativeChange ?? 0) > 0 ? (
          <Fragment>
            <p className="text-green-500 mr-1">▲</p>
            <p className="text-xs font-light text-green-500 me-1.5">
              {statistics?.relativeChange.toFixed(0) + "%"}
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <p className="text-red-500 mr-1">▼</p>
            <p className="text-xs font-light text-red-500 me-1.5">
              {statistics?.relativeChange.toFixed(0) + "%"}
            </p>
          </Fragment>
        )}
        <p className="text-xs font-light text-gray-500">Since last month</p>
      </dd>
    </div>
  );
};
export default StatCard;
