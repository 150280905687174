import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IUser } from "../../../modules/api/interfaces/user";
import SearchComponent from "../../../modules/components/SearchComponent";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";

export function UsersPage() {
  const { data: users, isLoading } = useFetchData<IUser[]>("/users");
  const [searchData, setSearchData] = useState<IUser[] | undefined>(users);
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_USERS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  useEffect(() => {
    if (searchData !== users) {
      setSearchData(users ? [...users] : []);
    }
  }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      label: "ID",
      accessor: (row: IUser) => row.userId,
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Name",
      accessor: (row: IUser) => row.name,
    },
    {
      label: "Email",
      accessor: (row: IUser) => row.email,
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Suspened?",
      accessor: (row: IUser) => row.isSuspended === true,
      render: (suspended: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            suspended ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {suspended ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <MainLayout
      title="Users"
      searchHeader={
        <Fragment>
          <SearchComponent
            placeholder="Search by name..."
            data={users || []}
            inputMode="text"
            filterAttribute="email"
            onSearch={(data) => {
              setSearchData(data);
            }}
          />
        </Fragment>
      }
      body={
        <Fragment>
          <Table
            data={searchData || []}
            columns={columns}
            tableActions={{
              deleteFn: (item) => console.log("Deleting", item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete ${item.name}?`,
            }}
            idAccessor={(row) => row.userId}
            isLoading={isLoading}
            emptyMessage="No users found"
          />
        </Fragment>
      }
    />
  );
}
