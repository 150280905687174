export interface ISubmission {
  submissionId: number;
  fullName: string;
  email: string;
  title: string;
  type: SubmissionType;
  description: string;
  open: boolean;
}

export enum SubmissionType {
  Select = -1,
  Feature = 0,
  Bug = 1,
}
