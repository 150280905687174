import { useState, useEffect, useMemo, useCallback } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { jwtDecode } from "jwt-decode";

interface TokenPayload {
  exp: number; // Expiry time in seconds (JWT standard)
}

// Custom hook to manage the token
export const useToken = () => {
  const { getToken, isAuthenticated } = useKindeAuth();
  const [token, setToken] = useState<string | undefined>("");

  // Function to fetch the token and set it
  const fetchToken = useCallback(async () => {
    if (isAuthenticated) {
      const accessToken = await getToken?.();
      setToken(accessToken || undefined);
    }
  }, [isAuthenticated, getToken]);

  useEffect(() => {
    // Function to schedule token refresh
    const scheduleTokenRefresh = (token: string) => {
      try {
        const decoded: TokenPayload = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeUntilExpiry = decoded.exp - currentTime;
        const refreshTime = timeUntilExpiry - 3600; // Refresh 1 hour before expiry

        if (refreshTime > 0) {
          setTimeout(fetchToken, refreshTime * 1000); // Schedule token refresh
        } else {
          fetchToken(); // Fetch immediately if past refresh time
        }
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    };

    if (token) {
      scheduleTokenRefresh(token);
    } else {
      fetchToken(); // Fetch token initially
    }
  }, [token, isAuthenticated, getToken, fetchToken]);

  // Use useMemo to return the token and avoid unnecessary re-calculations
  const memoizedToken = useMemo(() => token, [token]);

  return memoizedToken;
};
