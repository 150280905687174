import { useController, Control } from "react-hook-form";

interface FormSelectProps<T> {
  name: string;
  control: Control<any>;
  options: { label: string; value: T }[];
  label?: string;
  disabled?: boolean;
}

function FormSelect<T>({
  name,
  control,
  options,
  label,
  disabled = false,
}: FormSelectProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <select
        {...field}
        id={name}
        disabled={disabled}
        className={`disabled:opacity-100 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5 ${
          error ? "border-red-500" : ""
        }`}
      >
        {options.map((option) => (
          <option key={String(option.value)} value={String(option.value)}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
}

export default FormSelect;
