import { Link } from "react-router-dom";
import {
  BikeType,
  hexToBikeColorMap,
  IBike,
} from "../../../modules/api/interfaces/bike";
import Table from "../../../modules/components/table/Table";
import Button from "../../../modules/components/Button";
import SearchComponent from "../../../modules/components/SearchComponent";
import toast from "react-hot-toast";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { useDeleteItem } from "../../../modules/api/hooks/useDeleteItem";
import { Fragment } from "react/jsx-runtime";
import MainLayout from "../../../modules/components/MainLayout";
import { useEffect, useState } from "react";

export function BikesPage() {
  const { data: bikes, isLoading } = useFetchData<IBike[]>("/bikes");
  const [searchData, setSearchData] = useState<IBike[] | undefined>(bikes);

  const { mutate: deleteBike } = useDeleteItem("/bikes", {
    onSuccess: () => {
      toast.success("Bike deleted successfully!");
    },
    onError: (error: { response: { data: string } }) => {
      toast.error(error.response.data);
    },
  });

  useEffect(() => {
    if (searchData !== bikes) {
      setSearchData(bikes ? [...bikes] : []);
    }
  }, [bikes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (bike: IBike) => {
    deleteBike({ id: bike.frameNumber });
  };

  const columns = [
    {
      label: "Frame number",
      accessor: (row: IBike) => "SF" + row.frameNumber,
    },
    {
      label: "Color",
      accessor: (row: IBike) => row.color,
      render: (color: string) => (
        <div className="inline ml-2 sm:ml-0">
          <span className="mr-0 sm:mr-2" style={{ color: color }}>
            ⬤
          </span>
          <span className="hidden sm:table-cell">
            {hexToBikeColorMap[color]}
          </span>
        </div>
      ),
    },
    {
      label: "Type",
      accessor: (row: IBike) => BikeType[row.bikeType],
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
    {
      label: "Missing?",
      accessor: (row: IBike) => row.reports?.some((report) => report.active),
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
  ];

  return (
    <MainLayout
      title="Bikes"
      buttons={
        <Fragment>
          <Link to="./new">
            <Button
              title="Create bike"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </Fragment>
      }
      searchHeader={
        <Fragment>
          <SearchComponent
            placeholder="Search by frame number..."
            data={bikes || []}
            inputMode="numeric"
            filterAttribute="frameNumber"
            onSearch={(data) => {
              setSearchData(data);
            }}
          />
        </Fragment>
      }
      body={
        <Fragment>
          <Table
            data={
              (searchData || [])
                .sort((a: IBike, b: IBike) => {
                  const aActive = a.reports?.some(
                    (report: any) => report.active
                  );
                  const bActive = b.reports?.some(
                    (report: any) => report.active
                  );
                  return Number(bActive) - Number(aActive);
                })
                .filter((item: IBike) => item) || []
            }
            columns={columns}
            isLoading={isLoading}
            tableActions={{
              deleteFn: (item) => handleDelete(item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete bike - ${item.frameNumber}?`,
            }}
            idAccessor={(row) => row.frameNumber}
            emptyMessage="No bikes found"
            editPermission="EDIT_BIKE"
            deletePermission="DELETE_BIKE"
          />
        </Fragment>
      }
    />
  );
}

export default BikesPage;
