import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { useToken } from "./misc/useToken";
import { useNavigate } from "react-router-dom";

export const useFetchData = <T,>(endpoint: string) => {
  const accessToken = useToken();
  const navigate = useNavigate();

  const fetchData = async (): Promise<T> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<T>(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      switch (error.response?.status) {
        case 401:
          toast.error("401 - Unauthorized Access");
          toast.loading("Logging out...");
          setTimeout(() => navigate("/dashboard/logout"), 1500);
          break;
        case 403:
          toast.error("403 - Forbidden");
          break;
        default:
          toast.error(error.response?.data || `Failed to fetch data`);
          break;
      }
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: [endpoint],
    queryFn: fetchData,
    select: (data) => data,
  });

  return { isLoading, data, error };
};

export default useFetchData;
