import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useToken } from "./misc/useToken";

interface IUseDeleteItemProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useDeleteItem = <T extends { id: string }>(
  endpoint: string,
  props?: IUseDeleteItemProps
) => {
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const deleteItemById = async (id: string) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.delete(
      `${process.env.REACT_APP_API_URL}${endpoint}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["delete", endpoint],
    mutationFn: (item: T) => deleteItemById(item.id),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [endpoint] });
      props?.onSuccess?.();
    },
  });
};
